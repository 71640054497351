import React from 'react'

import {connect} from 'react-redux'
import Service from './Service'
import PageWrapper from './../PageWrapper';
import MetaTags from '../../metatags/MetaTags';

function ServicesPage(props){



	let content = {}

		if(props.pagesData){
			let currentPath = props.location.pathname.replace(/\/$/, "");
			content = props.pagesData.filter((page,i)=>{
				return ('/'+page.slug===currentPath)
			})[0]
		}


	return <PageWrapper>
				<MetaTags {...content} {...props} />
			<div className="services-page margin-top-page">

					<div className="content-row">
							<div className="col-2-offset col-8">

									<h2 className="grey-line" dangerouslySetInnerHTML={{__html:content.title.rendered}} />
									<div className="large-text text-area page-intro-copy" dangerouslySetInnerHTML={{__html:content.acf.intro_copy}} >


									</div>
							</div>	
					</div>

					<div className="content-row">
							<div className="col-3-offset col-6">

								<div className="services">
											{
												content.acf.services?

													content.acf.services.map((service,i)=>{

														return <Service {...service} key={"s"+i} />
 
													})


												: null
											}

								</div>

							</div>
					</div>

			</div>
			</PageWrapper>
}

function mapStateToProps(state){
	const {pagesData,optionsData} = state
	return {
		pagesData,
		optionsData
	}
}

export default connect(mapStateToProps)(ServicesPage)