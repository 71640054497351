import React from 'react'
import Player from '@vimeo/player';


class BackgroundVideo extends React.Component{


	constructor(props){

		super(props)


		const playerID = 'backgoundVimeoVideo' + Math.floor(Math.random()*6666);

		this.state = {
			isOnScreen:false,
			playerID
		}

		this.video = React.createRef();
		this.container = React.createRef();

		this.onScreenCheck = this.onScreenCheck.bind(this)

		this.playVideo = this.playVideo.bind(this)
		this.createVimeoPlayer = this.createVimeoPlayer.bind(this)

		this.container = React.createRef();
		this.video = React.createRef();


	}






	componentDidMount(){

		let video = this.container.current.querySelector('video')
		this.setState({video})


		if(this.props.playWhenOnScreen){

			window.requestAnimationFrame(this.onScreenCheck);
		}

		if(this.props.mp4_or_vimeo_video==='vimeo'){
			this.createVimeoPlayer();
		}
	}	

	componentWillUnmount(){

	}

	onScreenCheck(){
		
		if(this.container.current){

			const bounds = this.container.current.getBoundingClientRect();


			const margin = 100;

			if(bounds.bottom > margin && bounds.top < (window.innerHeight-margin) ){

				if(!this.state.isOnScreen){
						this.playVideo();
						this.setState({isOnScreen:true})
				}
			}else{

				if(this.state.isOnScreen){

						this.pauseVideo();
						this.setState({isOnScreen:false})
				}
			}



			window.requestAnimationFrame(this.onScreenCheck)

		}
	}



	createVimeoPlayer(){
		const options = {
		  id: this.props.videoId,
		  width: 640,
		  playsInline:true,
		  autoplay:!this.props.paused ,
		  loop:true ,
		  muted:true,
		  controls:false
		}
	
		const VimeoVideoPlayer = new Player(this.state.playerID, options);
	
		this.setState({VimeoVideoPlayer})
	
		VimeoVideoPlayer.getDuration().then((duration)=>{
			//console.log("Duration",duration)
			//this.setState({duration})
		}).catch((error)=>{
	
		})
	
		//VimeoVideoPlayer.on('timeupdate', this.videoOnUpdate)
	
		/*
		let currentTime = this.convertTime(this.refs.video.currentTime);
		let totalTime = this.convertTime(this.state.duration);
	
		this.setState({totalTime,currentTime})
		*/
	
		//VimeoVideoPlayer.on('ended',this.videoOnEnded)
		//VimeoVideoPlayer.on('pause',this.videoPaused)
		//this.refs.video.addEventListener('ended',this.videoOnEnded)
		//this.refs.video.addEventListener('pause',this.videoPaused)
	
		
	
	  };


	playVideo(){
		if(this.state.video){
			this.state.video.play();

		}

		if(this.state.VimeoVideoPlayer){
			this.state.VimeoVideoPlayer.play();
		}
	}

	pauseVideo(){
		if(this.state.video){
			this.state.video.pause();
		}
		if(this.state.VimeoVideoPlayer){
			this.state.VimeoVideoPlayer.pause();
		}
	}

	rewindVideo(){
		if(this.state.video){
			this.state.video.seek(0);
		}
	}


	render(){

		let autoplay = true //'autoplay'
		let loop = 'loop'

		

		if(this.props.paused){
			autoplay = false
		}

		/*
		return <div ref={this.container} className={"background-video "+(this.state.isOnScreen? 'on-screen' : 'off-screen')} dangerouslySetInnerHTML={{__html:`
					<video src=${this.props.video} 
						   playinline 
						    
						   muted
						   ${loop}  
						   ${autoplay} 

						   ></video>

			`}} /> */


		let src = this.props.video || '';



		
		return <div ref={this.container} className={"background-video "+(this.state.isOnScreen? 'on-screen' : 'off-screen')} >



					{this.props.mp4_or_vimeo_video==='mp4' ?
						<video ref={this.video}   src={src}
							playsInline
								disablePictureInPicture={true}
							muted
							loop  
							autoPlay={autoplay}

							></video>

					:   
						<div id={this.state.playerID} />
					
					}
			</div>
				

			 
	}
}

export default BackgroundVideo;