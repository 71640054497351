import React from 'react'
import {TimelineMax} from 'gsap'
//import DrawSVG from "./../../lib/gsap/DrawSVGPlugin";
//import SVG from 'react-inlinesvg';

class MenuBurger extends React.Component {


  constructor(props){
    super();
    this.state ={
        burger_tl: null,
        intro_tl:null,
        mouseOver_tl:null,

    }

    this.onMouseOut = this.onMouseOut.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onClick = this.onClick.bind(this)

    this.line1 = React.createRef();
    this.line2 = React.createRef();
    this.line3 = React.createRef();
    this.arrow = React.createRef();
  }

  
  componentDidMount(){
    this.setupAnimation();
  }

  componentDidUpdate(oldProps,oldState){

      //console.log(newProps);
      if(this.props.open===false && this.state.mouseOver_tl){
        //setTimeout(()=>{
          //console.log('revese')
          //this.state.mouseOver_tl.pause()
          //this.state.mouseOver_tl.reverse()
        //},500)
      }
  }





  animateIn(){

    let intro_tl = new TimelineMax({repeat:0})
    intro_tl.from(this.line1.current,.2,{left:'30%',width:'0%'},'-=.2')
    intro_tl.from(this.line2.current,.2,{left:'30%',width:'0%'},'-=.2')
    intro_tl.from(this.line3.current,.2,{left:'30%',width:'0%'},'-=.2')





  }


  reset(){
    console.log("Reset")
      this.state.mouseOver_tl.pause()
      this.state.mouseOver_tl.reverse()
  }

  setupAnimation(){


    //mouseOver_tl

    let mouseOver_tl = new TimelineMax({repeat:0})
    mouseOver_tl.to(this.line1.current,.1,{top:7},'-=0')
    mouseOver_tl.to(this.line2.current,.1,{top:7},'-=.1')
    mouseOver_tl.to(this.line3.current,.1,{top:7},'-=.1')

    mouseOver_tl.add(()=>{
      console.log("play");
    })

    mouseOver_tl.set(this.line1.current,{width:'55%'})
    mouseOver_tl.set(this.line2.current,{width:'55%'})
    mouseOver_tl.set(this.line3.current,{autoAlpha:0})

    mouseOver_tl.to(this.line1.current,.1,{rotation:55, top:11, left:-3 },'-=0')
    mouseOver_tl.to(this.line2.current,.1,{rotation:-55, top:12, left:13},'-=.1')
    mouseOver_tl.set(this.line1.current,{autoAlpha:0})
    mouseOver_tl.set(this.line2.current,{autoAlpha:0})
    mouseOver_tl.set(this.arrow.current,{autoAlpha:1})

    mouseOver_tl.pause();

    //mouseOver_tl.progress(0)
    this.setState({mouseOver_tl})
  }

  onMouseOver(){

    console.log("Mouse Over",this.props.open)
    if(!this.props.open){

      console.log('Play Animation')
      this.state.mouseOver_tl.pause()
      this.state.mouseOver_tl.play()
    }
  }
  onMouseOut(){
    console.log("Mouse Out")

    if(!this.props.open){
      this.state.mouseOver_tl.pause()
      this.state.mouseOver_tl.reverse()
    }
  }

  onClick(){
    if(this.state.mouseOver_tl.duration()!==this.state.mouseOver_tl.time()){
      this.state.mouseOver_tl.pause()
      this.state.mouseOver_tl.play()
    }
    this.props.onClick();
  }


  render () {

      let openClass = (this.props.open)? 'open' : 'closed';

      return (
          <div className="menu-burger-holder" onClick={this.onClick} onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
          <div className={"menu-burger "+(openClass)}  >
              <div className="arrow" ref={this.arrow}>
              <img src="/assets/global/scroll_arrow_blue.svg" alt="" />
              </div>
              <div className="line1" ref={this.line1} />
              <div className="line2" ref={this.line2} />
              <div className="line3" ref={this.line3} />
           </div>
           </div>
      )
  }
}

export default MenuBurger;
