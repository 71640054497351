import React from 'react'
import ScrollArrow from './../../scrollarrow/ScrollArrow'
import TitleShortCodes from '../../misc/TitleShortCodes';

export default function CaseStudyIntro(props){







	return <div className="content-row case-study-intro full-height-section">
				<div className="col-1-offset col-4">
					<div className="intro-text">
						<div className={'text text-area'}>
								<div className="project-type red-text" ><strong dangerouslySetInnerHTML={{__html:props.acf.project_type}}  /></div>
								<h3 className="red-line" ><TitleShortCodes title={props.title.rendered} /></h3>
								<div dangerouslySetInnerHTML={{__html:props.acf.intro_text}}/>
						</div>

						<ScrollArrow />

					</div>
				</div>

				<div className="col-6 col-1-offset">
						<div className="intro-image" style={{backgroundImage:`url('${(props.acf.intro_image)? props.acf.intro_image.url : ''}')`}} />
				</div>
			</div>
}		