import React from 'react'



export default function TextRow(props){

	return  <div className="content-row text-row use-margin">

					<div className="col-4-offset col-4">

						<div className="text text-area" dangerouslySetInnerHTML={{__html:props.text}} />


					</div>
			</div>

}