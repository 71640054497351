import React from 'react'
import CaseStudyIntro from './CaseStudyIntro'
import ImageRow from './ImageRow'
import TextRow from './TextRow'
import QuoteRow from './QuoteRow'
import VideoRow from './VideoRow'
import VideoPlayerRow from './VideoPlayerRow'
import RelatedCaseStudy from './RelatedCaseStudy'
import {Link} from 'react-router-dom'

import PageWrapper from './../PageWrapper'
import MetaTags from '../../metatags/MetaTags'

class  CaseStudy extends React.Component{


	constructor(props){
		super(props)

		this.state = {
			 nextCS:false,
			 previousCS:false
		}

		this.resize = this.resize.bind(this);
		this.getNextPreviousLink = this.getNextPreviousLink.bind(this)
	}

	componentDidMount(){

		window.addEventListener('resize',this.resize);

		this.getNextPreviousLink();

	}

	componentWillUnmount(){

		window.addEventListener('resize',this.resize);

	}

	resize(){

		this.forceUpdate();

	}


	getNextPreviousLink(){


		let previousCS,nextCS;
		if(this.props.caseStudiesData){
			this.props.caseStudiesData.map((cs,i)=>{
				if(cs.id===this.props.id){
					previousCS = this.props.caseStudiesData[i-1]
					nextCS = this.props.caseStudiesData[i+1]

					this.setState({previousCS,nextCS})
				}
			})
		}

	}




	render(){


	//console.log(this.props)

	const {content,related_case_studies} = this.props.acf

	function chunkArray(myArray, chunk_size){
		    let index = 0;
		    let arrayLength = myArray.length;
		    let tempArray = [];
		    
		    for (index = 0; index < arrayLength; index += chunk_size) {
		       	let  myChunk = myArray.slice(index, index+chunk_size);
		        // Do something if you want with the group
		        tempArray.push(myChunk);
		    }

		    return tempArray;
		}


		let chunk_size = 3;

		if(window.innerWidth < 769 ){
			chunk_size = 2;
		}
		if(window.innerWidth < 481){
			chunk_size = 1;
		}

		//console.log('chunk_size',chunk_size)

	const related_case_studies_rows =  chunkArray(related_case_studies,chunk_size)

	//console.log('related_case_studies_rows',related_case_studies_rows)
	return	<PageWrapper>
	     	<MetaTags {...content} {...this.props} />
			 <div className="case-study">	

				<CaseStudyIntro {...this.props} />
				<div className="scroll-anchor" />
 
				{	
					(content?
					content.map((item,i)=>{


							if(item.acf_fc_layout==='image_row'){
								return <ImageRow images={item.images} key={'row_'+i} />
							}else if(item.acf_fc_layout==='text_row'){
								return <TextRow {...item} key={'row_'+i} />
							}else if(item.acf_fc_layout==='quote_row'){
								return <QuoteRow {...item} key={'row_'+i} />
							}else if(item.acf_fc_layout==='video_row'){
								return <VideoRow {...item} key={'row_'+i} />
							}else if(item.acf_fc_layout==='video_player_row'){
								return <VideoPlayerRow {...item} key={'row_'+i} />
							
							}

							return null	
					})
					: null)
				}


				{related_case_studies?
					<div className="content-row">
						<div className="col-1-offset col-10">
							<div className="divider-horizontal-line " />
							<div className="related-case-studies">
								{related_case_studies_rows.map((row,j)=>{

									return <div key={"rcr"+j} className="related-case-studies-row">
											{
												row.map((item,i)=>{

														return <RelatedCaseStudy id={item.ID} key={'related'+i} />
																

												})
											}</div>
										})
							}
							</div>
						</div>
					</div>
				: null }


				{
					this.props?.optionsData?.add_next_previous_buttons && 

					<div className="content-row ">

						<div className="col-1-offset col-10">
							<div className="next-previous-project">
								<div>
									{this.state.previousCS && 
										<Link to={'/casestudies/'+this.state.previousCS.slug} className="link-labels previous"><span dangerouslySetInnerHTML={{__html:this.props?.optionsData?.previous_label}} /></Link>
									}
								</div>
								<div>
									{this.state.nextCS && 
										<Link to={'/casestudies/'+this.state.nextCS.slug} className="link-labels next"><span dangerouslySetInnerHTML={{__html:this.props?.optionsData?.next_label}} /></Link>
									}
								</div>
							</div>
						</div>

					</div>
					
				}

		   </div>
		   </PageWrapper>
		}
}

export default CaseStudy