import React from 'react'
import Footer from './../footer/Footer';



export default function PageWrapper(props){



		return <div className="page">
					
					{props.children}

				<Footer />
			   </div>	
}