import React from 'react'
import BackgroundVideo from './../../video/BackgroundVideo'


class VideoRow extends React.Component{



 	constructor(props){

 		super(props)


 		let videoFile = false
 		let videoId = false

 		if(this.props.mp4_or_vimeo_video==='vimeo'){

 			console.log('Video',this.props)
 			videoId = (this.props.vimeo_url)? this.props.vimeo_url.split('/').pop() : null

 		}else{
 			if(this.props.video){
 				videoFile=this.props.video.url

 			}
 		}

 		this.state={
 				videoId,
 				videoFile
 		}

 	}




	
  

  componentDidMount(){

  }






	render(){


			const classes = (this.props.options.width==='12/12'? "col-12 col-0-offset" : "col-10 col-1-offset" )

		return <div className={"content-row video-row use-margin"} ref={this.container}>
					<div className={classes}>
							{this.state.videoFile || this.state.videoId?
								<BackgroundVideo ref={this.video} video={this.state.videoFile} paused mp4_or_vimeo_video={this.props.mp4_or_vimeo_video} videoId={this.state.videoId}  playWhenOnScreen/>
							: null }

					</div>
				</div>
		}
}

export default VideoRow;