import React from 'react'
import {connect} from 'react-redux'
import Person from './Person'
import Greetings from './Greetings'
import PageWrapper from './../PageWrapper'
import MetaTags from '../../metatags/MetaTags'


class PeoplePage extends React.Component{


	constructor(props){
		super(props)

		this.state ={ 
			smallMinHeight:'auto',
			activePerson:''

		}

		this.smallPeople = React.createRef();

		//this.checkHeight = this.checkHeight.bind(this)

		this.resize = this.resize.bind(this)
		this.openPerson = this.openPerson.bind(this)


	}

	/*
	checkHeight(height){




		let smallMinHeight = (this.smallPeople.current.querySelector('.photo').offsetHeight+height)+'px'

				console.log("onMouseOver",height,smallMinHeight,this.smallPeople.current.querySelector('.photo').offsetHeight);
		this.setState({smallMinHeight})


	}*/


	componentDidMount(){
		window.addEventListener('resize',this.resize)
	}
	componentWillUnmount(){
		window.removeEventListener('resize',this.resize)
	}

	resize(){
		this.forceUpdate();
	}

	openPerson(name){
		console.log('open',name)

		this.setState({activePerson:name})
	}

 	chunkArray(myArray, chunk_size){
	    let index = 0;
	    let arrayLength = myArray.length;
	    let tempArray = [];
	    
	    for (index = 0; index < arrayLength; index += chunk_size) {
	       	let  myChunk = myArray.slice(index, index+chunk_size);
	        // Do something if you want with the group
	        tempArray.push(myChunk);
	    }

	    return tempArray;
	}

	render(){
		let content = {}

		if(this.props.pagesData){
			let currentPath = this.props.location.pathname.replace(/\/$/, "");
			content = this.props.pagesData.filter((page,i)=>{
				return ('/'+page.slug===currentPath)
			})[0]
		}



    // split the people if it's too narrow


    let smallPeopleArrayRows = [content.acf.small_people]


    //console.log((window.innerWidth / content.acf.small_people.length))
    
    if((window.innerWidth / content.acf.small_people.length) < 140 && window.innerWidth > 481){

		console.log('split smaller people into rows')


		
		let chunk =  Math.round(content.acf.small_people.length / 2); 
		if(content.acf.small_people.length > 20){
			chunk = Math.floor(window.innerWidth / 140);
		}
		

    	smallPeopleArrayRows = this.chunkArray(content.acf.small_people,chunk)



    }

    let smallPeopleWidth =  100 / smallPeopleArrayRows[0].length+'%'



	return <PageWrapper>
		
			<MetaTags {...content} {...this.props} />
			<div className="people-page margin-top-page">

					<div className="content-row">
							<div className="col-2-offset col-8">
									<Greetings greetings={content.acf.greetings}/>
									<div className="large-text page-intro-copy text-area" dangerouslySetInnerHTML={{__html:content.acf.intro_copy}} >


									</div>
							</div>	
					</div>
					{content.acf.large_people?
						<div className="content-row">
							<div className="col-12">
										
									<div className="people large">
												
										
											{
												content.acf.large_people.map((person, i)=>{
													const width = (100 / content.acf.large_people.length)+'%';

													const open = (this.state.activePerson === person.name)

													return  <Person key={'pl'+i} open={open} openCB={this.openPerson} {...person} width={width} />
												})
											}
										

												
											
									</div>


							</div>	
					</div>

					: null }


					{content.acf.small_people?


					<div className="content-row" >
							<div className="col-12">

								  <div className="people-small-wrapper">
									{smallPeopleArrayRows.map((row,j)=>{
										return <div key={"spr"+j} className="people small" >


													{


															
														row.map((person, i)=>{


															const width = (100 / row.length)+'%';
															const open = (this.state.activePerson === person.name)


															return  <Person key={'pl'+i} open={open} openCB={this.openPerson} splitName {...person} width={smallPeopleWidth}/>
														})
															
													}

											</div>
										})
								}</div>



							</div>	
					</div>

					: null }
			</div></PageWrapper>
		}
}

function mapStateToProps(state){
	const {pagesData,optionsData} = state
	return {
		pagesData,
		optionsData
	}
}

export default connect(mapStateToProps)(PeoplePage)