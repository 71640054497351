import React from 'react'
import PageWrapper from './../PageWrapper'

class Greetings extends React.Component{


	constructor(props){
		super(props)

		let greetings = this.props.greetings;

		  greetings = greetings.sort(() => Math.random() - 0.5);


		this.state = {
			greetings,
			active:0
		}

		this.next = this.next.bind(this)
	}


	next(){

		let active = this.state.active + 1;

		if(active === this.state.greetings.length){

			active = 0
		}

		this.setState({active})

	}

	componentDidMount(){
		let interval = setInterval(this.next,3000)

		this.setState({interval});
	}

	componentWillUnmount(){
		clearInterval(this.state.interval)
	}



	render(){


		return <div className="greetings"><h2 className="grey-line">
				{
					this.state.greetings.map((item,i)=>{
						return <div key={"greetings"+i} className={"greeting "+(this.state.active===i? ' active' :'')} dangerouslySetInnerHTML={{__html:item.text}} />
					})
				}
				<span dangerouslySetInnerHTML={{__html:'&nbsp;'}} /></h2>
				</div>

		  
	}



}

export default Greetings;