import React from 'react'



export default function QuoteRow(props){

	return  <div className="content-row quote-row use-margin">

					<div className="col-2-offset col-8">
						<div className="inner-quote text-area">
							<div className="quote" dangerouslySetInnerHTML={{__html:props.text}} />
							<div className="name-title">
								<div className="name" dangerouslySetInnerHTML={{__html:props.name}} />
								<div className="info">
									{props.title ?
										<div className="title"  dangerouslySetInnerHTML={{__html:props.title}}  />
									: null }
									{(props.name && props.title)?
										<div className="vertical-divider" > | </div>
										
									: null }
									{props.company?
										<div className="company"  dangerouslySetInnerHTML={{__html:props.company}}  />
									: null }
								</div>
							</div>
						</div>



					</div>
			</div>

}