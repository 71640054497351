const LoadedData = (state = [],action) => {

  let rtn

  //console.log('LoadedDataStore',action);

  switch (action.type) {



      



      case 'ADD_OPTIONS_DATA' :


        rtn = {
            ...state,
            optionsData : action.payload.acf
         }

        return rtn;


      case 'ADD_PAGES_DATA' :


        rtn = {
            ...state,
            pagesData : action.payload
         }

        return rtn;
      
      case 'ADD_CASESTUDIES_DATA' : 


        rtn = {
          ...state,
          caseStudiesData : action.payload
        }

        return rtn
      
      
      default :
        return state

  }

}

export default LoadedData
