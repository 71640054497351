import React from 'react'
import PageWrapper from './PageWrapper'
export default function Error404(props){


	console.warn("Error404");
	

	return <PageWrapper><div style={{height:'calc(100vh - 200px)',display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'}} >      
              	     <div><h1 style={{textAlign:'center'}}>404 error</h1>
              	     <p style={{textAlign:'center'}}>Page not found</p></div>
               </div></PageWrapper>
	
}