import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor() {
    super();

    this.state = {
      somethingToUpdate: 0,
    };

    this.locationsLineBreakFix = this.locationsLineBreakFix.bind(this);
    this.updateState = this.updateState.bind(this);

    this.locations = React.createRef();
  }

  componentDidUpdate() {
    //console.log("Component Did Update");
    this.locationsLineBreakFix();
  }

  updateState() {
    this.setState({ somethingToUpdate: Math.random() * 300 });
  }

  componentDidMount() {
    this.locationsLineBreakFix();
  }

  locationsLineBreakFix() {
    let locations = this.locations.current.querySelectorAll(".location");
    let yPos;

    for (let i = 0; i < locations.length; i++) {
      let top = locations[i].getBoundingClientRect().top;

      if (top > yPos) {
        locations[i - 1].classList.add("last-in-line");
      } else {
        locations[i].classList.remove("last-in-line");
      }

      yPos = top;
    }
  }

  render() {

    return (
      <footer>
        <div className="address">
          {this.props.optionsData.street_address ? (
            <div
              className="street"
              dangerouslySetInnerHTML={{
                __html: this.props.optionsData.street_address,
              }}
            />
          ) : null}

          {this.props.optionsData.street_address &&
          this.props.optionsData.telephone_number ? (
            <div className="divider" />
          ) : null}

          {this.props.optionsData.street_address ? (
            <div className="telephone-number">
              <a
                href={"tel:" + this.props.optionsData.telephone_number}
                dangerouslySetInnerHTML={{
                  __html: this.props.optionsData.telephone_number,
                }}
              />
            </div>
          ) : null}
        </div>

        {window.innerWidth < 768 &&
        (this.props.optionsData.twitter || this.props.optionsData.instagram) ? (
          <div className="icons mobile">
            {this.props.optionsData.twitter ? (
              <a
                href={this.props.optionsData.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon twitter"></div>
              </a>
            ) : null}
            {this.props.optionsData.linkedin ? (
              <a
                href={this.props.optionsData.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon linkedin"></div>
              </a>
            ) : null}
            {this.props.optionsData.instagram ? (
              <a
                href={this.props.optionsData.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon instagram"></div>
              </a>
            ) : null}
            {this.props.optionsData.email ? (
              <a
                href={"mailto:" + this.props.optionsData.email}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon email"></div>
              </a>
            ) : null}
          </div>
        ) : null}

        {window.innerWidth > 768 ? (
          <div className="icons">
            {this.props.optionsData.twitter ? (
              <a
                href={this.props.optionsData.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon twitter"></div>
              </a>
            ) : null}
            {this.props.optionsData.linkedin ? (
              <a
                href={this.props.optionsData.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon linkedin"></div>
              </a>
            ) : null}
            {this.props.optionsData.instagram ? (
              <a
                href={this.props.optionsData.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon instagram"></div>
              </a>
            ) : null}
            {this.props.optionsData.email ? (
              <a
                href={"mailto:" + this.props.optionsData.email}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon email"></div>
              </a>
            ) : null}
          </div>
        ) : null}

        <div className="locations offices" ref={this.locations}>
          <div className="location">
            <a
              href="https://kastner.agency/offices/brunnen"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brunnen
            </a>
          </div>
          <div className="location">
            <a
              href="https://www.kastner-budapest.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              BUDAPEST
            </a>
          </div>
          <div className="location">
            <a
              href="https://www.kastner-frankfurt.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FRANKFURT
            </a>
          </div>
          <div className="location">
            <a
              href="https://www.kastner-london.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LONDON
            </a>
          </div>
          <div className="location">
            <a
              href="https://www.kastner-los-angeles.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LOS ANGELES
            </a>
          </div>
          <div className="location">
            <a
              href="https://www.kastner-madrid.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              MADRID
            </a>
          </div>
          <div className="location">
            <a
              href="https://kastner.agency/offices/milan"
              target="_blank"
              rel="noopener noreferrer"
            >
              MILANO
            </a>
          </div>
          <div className="location">
            <a
              href="https://kastner.agency/offices/oberhofen"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oberhofen
            </a>
          </div>
        </div>

        {this.props.optionsData?.legal_menu ? (
          <div className="locations legal-menu">
            {this.props.optionsData?.legal_menu.map((item, index) => {
              return (
                <div key={`legal${index}`} className="location">
                  {item.link?.includes("https") ? (
                    <a
                      href={item?.link}
                      target="_blank"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link to={item.link}>{item.label}</Link>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}

        <div className="locations global-link">
          <a
            href="https://kastner.agency"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kastner.agency
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  const { optionsData } = state;

  return {
    optionsData,
  };
};

export default connect(mapStateToProps)(Footer);
