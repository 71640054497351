import React from 'react'
import {TweenMax,gsap} from 'gsap'
import Draggable from "gsap/Draggable";
import Player from '@vimeo/player';

//import Fullscreen from 'react-fullscreen-crossbrowser';

class VimeoVideoPlayer extends React.Component {


  constructor(props){
    super(props)


    //console.log("video",props);

    gsap.registerPlugin(Draggable);

    let videoLink =   props.vimeo_link ||  props.video_link

    let videoId = (videoLink)? videoLink.split('/').pop() : null

    let paused = !props.playInBackground

    if(this.props.startVideoMuted){
        paused = !this.props.startVideoMuted;
    }

    const playerID = 'VimeoPlayer'+Math.floor(Math.random()*99999)

  

    this.state= {
      currentTime:'00:00',
      audioDragable:null,
      timeDraggable:null,
      progress: 0,
      paused,
      played:false,
      isFullscreenEnabled: false,
      controlsHideTimeout:null,
      showControls:true,
      videoFiles:null,
      videoId,
      htmlVideoId:("video"+Math.round(Math.random()*666)),
      videoUpdateInterval: null,
      dragging:false,
      playerID,
      isVideoMuted: this.props.startVideoMuted
    }

    this.videoLoad = this.videoLoad.bind(this);
    this.videoOnUpdate = this.videoOnUpdate.bind(this);
    this.playPauseVideo = this.playPauseVideo.bind(this);
    this.videoOnEnded = this.videoOnEnded.bind(this)
    this.setVolume = this.setVolume.bind(this);
    this.videoSeek = this.videoSeek.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.showControls = this.showControls.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.videoPaused = this.videoPaused.bind(this);
    this.dragStart = this.dragStart.bind(this);

    this.fullscreenToggle = this.fullscreenToggle.bind(this)

    this.mobileEndOfFullscreen = this.mobileEndOfFullscreen.bind(this)


    this.iOsGoFullscreen = this.iOsGoFullscreen.bind(this)

    this.detectEndOfFullscreen = this.detectEndOfFullscreen.bind(this)
    this.didFullScreenEnd = this.didFullScreenEnd.bind(this)
    this.endDetectEndOfFullscreen = this.endDetectEndOfFullscreen.bind(this)

    this.createVimeoPlayer = this.createVimeoPlayer.bind(this);

    this.unMuteVideo = this.unMuteVideo.bind(this)
  }


 

  componentDidMount(){
    //
    this.refs.videoPlayer.addEventListener('mousemove',this.onMouseMove)



    // HACK:


    //let videoUpdateInterval = setInterval(this.onVideoUpdate,50);

    //window.addEventListener("keypress", (e)=> console.log(e) );
    this.detectEndOfFullscreen()

    this.createVimeoPlayer();

    this.setupControls();

  }

  createVimeoPlayer(){


         
    let shouldAutoPlay = this.props.playInBackground

    if(this.props.startVideoMuted){
      shouldAutoPlay = this.state.isVideoMuted
    }

    const options = {
      id: this.props.video,
      playsInline:(shouldAutoPlay? true : false),
      autoplay:(shouldAutoPlay? true : false) ,
      loop:(this.props.loop? true : false) ,
      muted:(shouldAutoPlay? true : false),
      controls:false
    }

    //console.log('options',options)

    const VideoPlayer = new Player(this.state.playerID, options);


    this.setState({VideoPlayer})

    VideoPlayer.getDuration().then((duration)=>{
        //console.log("Duration",duration)
        this.setState({duration,totalTime:this.convertTime(duration)})
    }).catch((error)=>{

    })

    VideoPlayer.on('timeupdate', this.videoOnUpdate)

    /*
    let currentTime = this.convertTime(this.refs.video.currentTime);
    let totalTime = this.convertTime(this.state.duration);

    this.setState({totalTime,currentTime})
    */

    VideoPlayer.on('ended',this.videoOnEnded)
    VideoPlayer.on('pause',this.videoPaused)
    //this.refs.video.addEventListener('ended',this.videoOnEnded)
    //this.refs.video.addEventListener('pause',this.videoPaused)

    

  };
  
  componentWillUnmount(){
      this.refs.videoPlayer.removeEventListener('mousemove',this.onMouseMove)
      clearInterval(this.state.videoUpdateInterval)
      this.endDetectEndOfFullscreen()
  }

  onMouseMove(){
    if(this.state.controlsHideTimeout){
      clearTimeout(this.state.controlsHideTimeout)
    }
    if(this.state.paused===false){
      let controlsHideTimeout
      controlsHideTimeout = setTimeout(this.hideControls,1000)
      this.setState({ controlsHideTimeout})
    }

    this.showControls();
  }

  hideControls(){
    console.log("hide controls");
    this.setState({showControls:false})
  }
  showControls(){
    console.log("show controls");
    this.setState({showControls:true})
  }

  setupControls(){

    let audioDragable = Draggable.create(this.refs.audioDrag, {type:"x", edgeResistance:1, bounds:this.refs.track, throwProps:true})[0];
        //console.log(volumeDrag);
        audioDragable.addEventListener("drag", this.setVolume);
        audioDragable.addEventListener("press", this.setVolume);

        TweenMax.set(this.refs.audioDrag,{x:100});


    let timeDraggable = Draggable.create(this.refs.dragTime, {type: "rotation", throwProps: true,bounds:{minRotation:0, maxRotation:360}})[0]
        timeDraggable.addEventListener("drag", ()=>{
          console.log('timeDraggable drag');
          this.videoSeek()
          this.setState({dragging:true})
        });


        timeDraggable.addEventListener("dragstart", ()=>{
            console.log('timeDraggable dragstart');
            this.dragStart();
        })

        timeDraggable.addEventListener("press", (e)=>{

          console.log('timeDraggable press');

          this.dragStart();

        });

        timeDraggable.addEventListener("dragend",()=>{
          console.log("Video: dragend");
          this.setState({dragging:false})
        })



        this.setState({audioDragable,timeDraggable})


  }

  dragStart(){
    //console.log(window.event.clientY);

    let dragBounds = this.refs.centerContainer.getBoundingClientRect()
    //console.log(dragBounds);

    let mouseX = window.event.clientX
    let mouseY = window.event.clientY


    var deltaX = mouseX - (dragBounds.left + (dragBounds.width/2));
    var deltaY = mouseY - (dragBounds.top + (dragBounds.height/2));
    var rad = Math.atan2(deltaY, deltaX); // In radians

    var deg = rad * (180 / Math.PI)

      deg += 90

      if(deg < 0){
        deg = 360 + deg
      }




    let progress = (deg/360)

    let currentTime = progress*this.state.duration;
      console.log(deg,progress,currentTime);
    TweenMax.set(this.refs.dragTime, {rotation:progress*360})
    this.state.timeDraggable.update();

    this.setState({progress})

      this.videoSeek()

  }

  setVolume(){

    let volume = this.state.audioDragable.x / this.state.audioDragable.maxX

    //console.log("set volume",volume , this.refs.video.volume  );
    //this.refs.video.volume = volume
    //this.refs.videoPlayer

    this.state.VideoPlayer.setVolume(volume).then(function(vol) {
        console.log('Volume Set')
      }).catch(function(error) {
            console.warn(error)
      });
  }

  unMuteVideo(){
    this.setState({isVideoMuted:false})
    this.state.VideoPlayer.setVolume(.7)
  }

  moveVolume(){
    //this.refs.track.
  }

  videoLoad(e){
    //console.log("Video",this.refs.video.currentTime,e);


    //this.refs.video.addEventListener('timeupdate',()=>{
      //console.log("Video Time Update",  this.refs.video.currentTime);
    //})

  }

  videoOnUpdate(data){


      let currentTime = this.convertTime(data.seconds);
      let totalTime = this.convertTime(this.state.duration);
      let progress = data.seconds/this.state.duration



          if(!this.state.dragging){

                //console.log(this.refs.video.stream.paused)


                TweenMax.set(this.refs.dragTime, {rotation:(data.seconds/this.state.duration)*360})
                this.state.timeDraggable.update()
                this.setState({totalTime,currentTime,progress})


                
            }else{
              this.setState({totalTime,currentTime})
            }

  }

  videoSeek(){
    //console.log(this.state.timeDraggable.rotation/360,this.state.dragging);



    let time = this.state.duration*(this.state.timeDraggable.rotation/360)

    //console.log(time,this.refs.video.currentTime);
    //this.refs.video.pause()
    //this.refs.video.currentTime = time;
    //this.state.VideoPlayer.pause();
    this.state.VideoPlayer.setCurrentTime(time)

    console.log(time)
    if(this.state.dragging){


      let progress = (this.state.timeDraggable.rotation/360)

      //console.log('progress',progress);
      this.setState({progress})
    }

    
  }

  videoOnEnded(){

        console.log("Video Ended");
        this.setState({paused:this.refs.video.paused});
        this.setState({showControls:true});
        //this.refs.video.currentTime = 0;
  }

  playPauseVideo(){


    // find all other videos and pause them
    let allVideos = document.querySelectorAll('.video-player video') ///document.querySelectorAll('.playing .inner-button')
    for(let i=0;i < allVideos.length;i++){
      //console.log('Check video',this.refs.video.getAttribute('id')!==allVideos[i].getAttribute('id'));
      if(this.refs.video.getAttribute('id')!==allVideos[i].getAttribute('id')){
        allVideos[i].pause();
      }
    }



    

    if(this.state.VideoPlayer){
      console.log("Play Pause");
      this.state.VideoPlayer.getPaused().then((paused)=> {
        // paused = whether or not the player is paused
         console.log('paused', paused, this.state.VideoPlayer)
          if(paused){

            console.log('paused', paused, this.state.VideoPlayer)
            this.state.VideoPlayer.play()
            if(this.isIOs()){
              console.log('iOS FullScreen');
              this.iOsGoFullscreen()
            }
          }else{
            this.state.VideoPlayer.pause()
          }

          this.setState({paused:!paused,played:true,dragging:false})
      }).catch(function(error) {
          // an error occurred
      });

       


         


    }else{
       //alert('No video loaded, please check that it is set correctly in the back-end');
     }

  }

  videoPaused(){

    // just check if the component is mounted
    if(this.refs.video){
      this.setState({paused:this.refs.video.paused});
    }
  }

  convertTime(secs){

    let minutes = parseInt(secs/ 60, 10);
    let seconds = Math.round(secs % 60);

    if(minutes < 10){
      minutes = '0'+minutes
    }
  

    if(seconds < 10){
      seconds = '0'+seconds
    }



    return minutes +":"+seconds

  }

  isMobile() {
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;

         // Windows Phone must come first because its UA also contains "Android"
       if (/windows phone/i.test(userAgent)) {
           return true
       }

       if (/android/i.test(userAgent)) {
           return true
       }

       // iOS detection from: http://stackoverflow.com/a/9039885/177710
       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
           return true
       }

       return false
   }

   isAndroid(){
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;

       if (/android/i.test(userAgent)) {
           return true
       }

       return false
   }

   isIOs(){
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;

       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
           return true
       }

       return false
   }



  fullscreenToggle(){

      let isFullscreenEnabled = !(document.webkitFullscreenElement || document.fullscreenElement || document.mozFullscreenElement) //(!this.state.isFullscreenEnabled)


      this.setState({isFullscreenEnabled });


      let fullScreenContainer = this.refs.fullScreenContainer


      //let requestFullscreen = fullScreenContainer.requestFullscreen()	|| fullScreenContainer.webkitRequestFullscreen()	|| fullScreenContainer.mozRequestFullScreen()	|| fullScreenContainer.msRequestFullscreen()	|| fullScreenContainer.webkitRequestFullscreen();

      //console.log('isFullscreenEnabled',isFullscreenEnabled);

      // Go fullscreen
      if(isFullscreenEnabled && !this.isIOs()){



            if (fullScreenContainer.requestFullscreen) {
                fullScreenContainer.requestFullscreen();
              } else if (fullScreenContainer.webkitRequestFullscreen) {
                fullScreenContainer.webkitRequestFullscreen();
              } else if (fullScreenContainer.mozRequestFullScreen) {
                fullScreenContainer.mozRequestFullScreen();
              } else if (fullScreenContainer.msRequestFullscreen) {
                fullScreenContainer.msRequestFullscreen();
            }else{
              console.log("Fullscreen Not Supported on video container");

              if(this.refs.video.webkitEnterFullscreen){
                this.refs.video.webkitEnterFullscreen()
                console.log("Video Playing in Native Player");
                this.refs.video.addEventListener('webkitendfullscreen', this.fullscreenToggle);
              }
            }

      }


      // Close fullscreen
      if(!isFullscreenEnabled){

          if(this.isMobile()){
            //console.log('Mobile Exit');
            this.refs.video.removeAttribute('controls')
          }
          if (document.exitFullscreen) {
          	document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
          	document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
          	document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
          	document.msExitFullscreen();
          }

      }

  }

  detectEndOfFullscreen(){

    let fullScreenContainer = this.refs.fullScreenContainer
    if(fullScreenContainer.requestFullscreen){
      fullScreenContainer.addEventListener('endfullscreen', this.didFullScreenEnd);
      fullScreenContainer.addEventListener('fullscreenchange', this.didFullScreenEnd);
    }else{

      fullScreenContainer.addEventListener('webkitendfullscreen', this.didFullScreenEnd);
      fullScreenContainer.addEventListener('webkitfullscreenchange', this.didFullScreenEnd);
    }
  }

  endDetectEndOfFullscreen(){

    let fullScreenContainer = this.refs.fullScreenContainer
    if(fullScreenContainer.requestFullscreen){
      fullScreenContainer.removeEventListener('endfullscreen', this.didFullScreenEnd);
      fullScreenContainer.removeEventListener('fullscreenchange', this.didFullScreenEnd);
    }else{

      fullScreenContainer.removeEventListener('webkitendfullscreen', this.didFullScreenEnd);
      fullScreenContainer.removeEventListener('webkitfullscreenchange', this.didFullScreenEnd);
    }
  }

  didFullScreenEnd(){


    let fullscreenDocument = document.webkitFullscreenElement || document.fullscreenElement || document.mozFullscreenElement


    //alert("End of fullscreen")

    if(fullscreenDocument){
        //console.log('didFullScreenEnd? No');
    }else{
      //console.log('didFullScreenEnd? YES');
      this.setState({isFullscreenEnabled:false });
      //this.setState({isFullscreenEnabled:false });
      //this.refs.video.removeAttribute('controls')
      //console.log("We are NOT full screen",this.refs.video);
    }
  }

  iOsGoFullscreen(){

    let fullScreenContainer = this.refs.video;

    if(this.isAndroid()){
      fullScreenContainer = this.refs.fullScreenContainer;
    }

    //console.log('this.isAndroid()',this.isAndroid());
    //console.log('fullScreenContainer',fullScreenContainer);

    this.refs.video.setAttribute('controls','')

    //console.log('this.refs.video.requestFullscreen',fullScreenContainer.requestFullscreen);
    //console.log('this.refs.video.webkitEnterFullscreen',fullScreenContainer.webkitEnterFullscreen);

    if(fullScreenContainer.requestFullscreen){
      //console.log('this.refs.video.requestFullscreen()');
      fullScreenContainer.requestFullscreen()
      fullScreenContainer.addEventListener('endfullscreen', this.mobileEndOfFullscreen);
      fullScreenContainer.addEventListener('fullscreenchange', this.mobileEndOfFullscreen);
    }else{

      //console.log('this.refs.video.webkitEnterFullscreen()');
      fullScreenContainer.webkitEnterFullscreen()
      fullScreenContainer.addEventListener('webkitendfullscreen', this.mobileEndOfFullscreen);
      fullScreenContainer.addEventListener('webkitfullscreenchange', this.mobileEndOfFullscreen);
    }
  }

  mobileEndOfFullscreen(){



    let fullscreenDocument = document.webkitFullscreenElement || document.fullscreenElement || document.mozFullscreenElement


    //console.log('fullscreenDocument',fullscreenDocument);
    //alert("End of fullscreen")

    if(fullscreenDocument){
      //console.log("We are full screen");
    }else{
      //console.log("We are NOT full screen");
      this.setState({isFullscreenEnabled:false });
      this.refs.video.removeAttribute('controls')
      //console.log("We are NOT full screen",this.refs.video);
    }
  }

  render () {




    let circleLine = 1000-((Math.PI*120)*this.state.progress);


   

    let videoFile = this.props.video


    // HACK
    //videoFile = '/assets/video/placeholder.mp4'



    //console.log('videoFile',videoFile,this.state.videoFiles);

    let showControls = (this.state.dragging)? true : this.state.showControls;

    let posterImage = ( this.props.poster_image)? this.props.poster_image.url :'';

    if(this.props.poster_image){

        if(this.props.poster_image.url && window.innerWidth < 1025){
            posterImage = this.props.poster_image.sizes.large
        }

        if(this.props.poster_image.url && window.innerWidth < 769){
            posterImage = this.props.poster_image.sizes.medium_large
        }


        // check if it's portrait
        if(this.props.portrait_poster_image && window.innerWidth < window.innerHeight){
            posterImage = this.props.portrait_poster_image.sizes.medium_large
        }
      }


        /*
                <div>
                  <video ref="video" allowFullScreen 

                    playsInline={this.props.playInBackground? true : false} 
                    autoPlay={this.props.playInBackground? true : false}  
                    loop={this.props.playInBackground? true : false} 
                    muted={this.props.playInBackground? true : false}  
 

                    className="actual-video" 
                    id={this.state.htmlVideoId} 
                    onLoadedData={this.videoLoad} 
                    onTimeUpdate={this.videoOnUpdate}  
                    preload="auto" >
                    <source src={videoFile} />
                  </video>
                    {(this.props.poster_image)?
                        <div className="video-poster" style={{backgroundImage:`url('${posterImage}')`}} />
                    :null
                    }
                </div> */


    return(<div ref="videoPlayer" className={"video-player vimeo-video-player "+(this.props.className? this.props.className+" " : '')+((this.state.isFullscreenEnabled)? ' fullscreen' : 'not-fullscreen')+((this.state.paused)? " paused" : " playing")+((this.state.played)?' played' : ' not-played')+((this.state.dragging)?' dragging' : ' ')+(this.state.isVideoMuted? ' show-unmute-button' :'')}>
          <div ref="fullScreenContainer" allowFullScreen >
              {


                    <div id={this.state.playerID} className="vimeo-container"></div>
                
            }
             {(this.props.poster_image)?
                        <div className="video-poster" style={{backgroundImage:`url('${posterImage}')`}} />
                    :null
                    }
            <div className="vail"></div>
            <div className={"controls "+((showControls)? 'show' : 'hide')}>

              <div className="centred-containter" ref="centerContainer">
                {this.state.duration!==0?
                 <div className="current-time">{this.state.currentTime}</div>
                 : null}

                  <div className="progress-circle" >
                    <svg height="130" width="130">
                        <circle cx="65" cy="65" r="60" stroke="#fff" strokeWidth="5" fill="transparent" style={{strokeDashoffset:circleLine}} />
                    </svg>
                  </div>

                <div className="drag-time" ref="dragTime" id="dragTime" > - Drag</div>

                <div className={"play-btn "+((this.state.paused)? "paused" : "playing")} >
                  <div className="play-text">Play</div>
                  <div className="pause-text">Pause</div>
                  <div className="inner-button" onClick={this.playPauseVideo} />
                </div>
                <div className="unmute-button" onClick={this.unMuteVideo}></div>

                {this.state.duration!==0?
                  <div className="total-time">{this.state.totalTime}</div>
                  : null }
              </div>
              <div className="audio-controls">

                <div className="audio-off" />
                  <div className="track" id="audioTrack" ref="track">
                    <div id="audioDrag" ref="audioDrag" className="dragger"></div>
                  </div>
                <div className="audio-on" />

              </div>

              <div className="fullscreen-toggle"  onClick={this.fullscreenToggle}>

              </div>
            </div>


          </div>


          </div>);

  }
}

export default VimeoVideoPlayer;
