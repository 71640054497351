import React from 'react'

class ImageRow extends React.Component{


	constructor(props){

		super(props)
		this.state = {

		}

		this.onResize = this.onResize.bind(this)
	}


	onResize(){
		this.forceUpdate();
	}

	componentDidMount(){
		window.addEventListener('resize',this.onResize);
	}

	componentWillUnmount(){
		window.removeEventListener('resize',this.onResize);
	}


	render(){

		let width = ( 100 / this.props.images.length) + '%';

		

		return <div className="content-row image-row use-margin">

						<div className="col-12">
							<div className="images">
								{this.props.images? 
										this.props.images.map((item,i)=>{


												if(item.options.width){
													width = item.options.width +'%'
												}

												if(item.options.width_mobile && window.innerWidth < 481 ){
													width = item.options.width_mobile+'%'
												}


												let style={
													width
												}



												return <div key={'image_'+item.image.id+'_'+i} style={style} className="image" ><img src={item.image.url} alt="" /></div>

										})
								:null
								}
							</div>
						</div>

				</div>
 	}
}

export default ImageRow;