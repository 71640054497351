import React from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {TimelineMax} from 'gsap'



class Menu extends React.Component {

  constructor(){
    super();

    this.state ={
      animation_tl: null,
      lineAnimation_tl:null,
      menuItemsMinHeight:0,
      menuHeight: window.innerHeight
    }

    this.setupAnimation = this.setupAnimation.bind(this)
    this.onResize = this.onResize.bind(this)

    this.hackForAddingAdelay = React.createRef();
    this.hackForAddingAdelay2 = React.createRef();
    this.menuItems = React.createRef();
  }


  setupAnimation(){

      let animation_tl = new TimelineMax({repeat:0})
      let lineAnimation_tl = new TimelineMax({repeat:0})


      let items = this.menuItems.current.querySelectorAll('li')


      animation_tl.to(this.hackForAddingAdelay.current,1,{alpha:1})
      /*
      lineAnimation_tl.add(()=>{
        document.querySelector('body').classList.remove('no-scroll')
      })
      lineAnimation_tl.add(()=>{
        document.querySelector('body').classList.add('no-scroll')
      })
      */

      for(let i = 0;i < items.length;i++){


          animation_tl.from(items[i],.7,{alpha:0},'-=.5');
      }

      lineAnimation_tl.to(this.hackForAddingAdelay2.current,1.5,{alpha:1})

      for(let i = 0;i < items.length;i++){
          let line = items[i].querySelector('.line')
          lineAnimation_tl.from(line,.7,{width:0},'-=.5');

      }





      //animation_tl.staggerFrom(items,.5,{alpha:0,x:0},.5)

      animation_tl.pause();
      lineAnimation_tl.pause();

      this.setState({animation_tl,lineAnimation_tl})

  }


  componentDidUpdate(oldProps,oldState){

    //console.log('newProps',newProps);

    if(this.props.open===true && oldProps.open===false){
      this.state.animation_tl.timeScale(1)
      this.state.lineAnimation_tl.timeScale(1)

      this.state.animation_tl.progress(0)
      this.state.lineAnimation_tl.progress(0)

      //this.state.lineAnimation_tl.delay(2)

      this.state.animation_tl.play();
      this.state.lineAnimation_tl.play();
    }

    // Making sure it will always play open
    if(this.props.open===true){
      this.state.animation_tl.play();
    }

    if(this.props.open===false && oldProps.open===true){
      this.state.animation_tl.timeScale(.2)
      this.state.lineAnimation_tl.timeScale(.2)

      this.state.animation_tl.reverse();
      this.state.lineAnimation_tl.reverse();
    }
  }






  componentDidMount(){
    this.setupAnimation();

    // set min height

    let menuItemsMinHeight = this.menuItems.current.getBoundingClientRect().height;

    this.setState({menuItemsMinHeight})

    window.addEventListener('resize',this.onResize)
  }

  onResize(){
    this.setState({menuHeight:window.innerHeight});
  }




  render () {


    let openClass = (this.props.open)? ' open' : ' closed';

    let mobileStyle = {}

      if(window.innerWidth < 1025 && this.props.open){
        mobileStyle.height = this.state.menuHeight +'px';
      }

    return(
      <div className={"main-menu"+(openClass)} style={mobileStyle}>

          <div className="menu-outer" style={{minHeight:this.state.menuItemsMinHeight+'px'}}>
            <ul ref={this.menuItems}>
                { 
                  ( this.props.optionsData.menu_items?
                  this.props.optionsData.menu_items.map((item,i)=>{
                        return (<li key={'menu'+i} ><NavLink to={item.menu_link} onClick={this.props.toggle}>{item.menu_label}</NavLink> <div className="line" /></li>)
                    })
                  : null )
              }
            </ul>
          </div>
          <div ref={this.hackForAddingAdelay} />
          <div ref={this.hackForAddingAdelay2} />
      </div>
    )
  }
}

const mapStateToProps = (state)=>{
  const optionsData = state.optionsData, pagesData = state.pagesData;

  //console.log('state',state);
  return {
    optionsData,
    pagesData
  }
}

export default connect(mapStateToProps)(Menu);
