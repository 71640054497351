import React from 'react'
import {Link} from 'react-router-dom'
import TitleShortCodes from '../../misc/TitleShortCodes';

import BackgroundVideo from './../../video/BackgroundVideo'

class CaseStudyPreview extends React.Component{

		constructor(props){


			super(props)

			this.videoPlayer = React.createRef();

			this.onMouseOver = this.onMouseOver.bind(this)
			this.onMouseOut = this.onMouseOut.bind(this)


 		let videoFile = false
 		let videoId = false

 			let {content} = this.props

 		if(content.acf.mp4_or_vimeo_video==='vimeo'){

 			//console.log('Video',this.props)
 			videoId = (content.acf.vimeo_url)? content.acf.vimeo_url.split('/').pop() : null

 		}else{
 			if(content.acf.main_feature_video){
 				videoFile=content.acf.main_feature_video.url

 			}
 		}

 			this.state={
 				videoId,
 				videoFile
 		}



		}



		onMouseOver(){




			if(this.videoPlayer.current){
					this.videoPlayer.current.playVideo();
			}

		}

		onMouseOut(){


			if(this.videoPlayer.current){
					this.videoPlayer.current.pauseVideo();
			}

		}

		 




	

		render(){


			let {content} = this.props


			let mainStyle = {
					
			}

			if(content.acf.main_feature_image){
				mainStyle.backgroundImage=`url('${ content.acf.main_feature_image.url}')`
			}


			return <div className="case-study-preview content-row" onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
						<div className="main-feature col-8">
								<div className="background" style={mainStyle}>
									{	content.acf.mp4_or_vimeo_video === 'vimeo' && this.state.videoId? 
											<React.Fragment>
												<BackgroundVideo paused mp4_or_vimeo_video={'vimeo'} ref={this.videoPlayer} videoId={this.state.videoId} />
											</React.Fragment>
										: null
										}
									{content.acf.main_feature_video && content.acf.main_feature_video ?
												<React.Fragment>
												<BackgroundVideo paused ref={this.videoPlayer} mp4_or_vimeo_video={'mp4'} video={content.acf.main_feature_video.url} />
												</React.Fragment>
										: null
									
									}
								</div>
							<div className="title">
									<div>
										<h3 ><TitleShortCodes title={content.title.rendered} /></h3>
									</div>
							</div>
						</div>

						<div className="col-4">
							<div className="thumbnails">
									{
										(content.acf.main_feature_thumbnails?
										content.acf.main_feature_thumbnails.map((thumb,i)=>{


											let thumbStyle={
												
													
											}

											if(thumb.height==='100%'){
												thumbStyle.gridRow = 'span 2'
											}
											if(thumb.width==='100%'){
												thumbStyle.gridColumn='span 2'
											}

											if(window.innerWidth < 769){
												if(thumb.mobile_width==='100%'){
													thumbStyle.gridColumn='span 4'
												}
												if(thumb.mobile_width==='75%'){
													thumbStyle.gridColumn='span 3'
												}
												if(thumb.mobile_width==='50%'){
													thumbStyle.gridColumn='span 2'
												}
												if(thumb.mobile_width==='25%'){
													thumbStyle.gridColumn='span 1'
												}
												

												thumbStyle.gridRow  = 1;
											}


											return <div className="thumbnail" data-mobile-width={thumb.mobile_width} data-width={thumb.width} data-height={thumb.height} style={thumbStyle} key={'th'+content.id+'_'+i}>
														<div className="thumbnail-inner" style={{backgroundImage:`url('${thumb.image.url}')`}} />
													</div>

										})
										: null)
									}
							</div>
						</div>
						<Link to={'/casestudies/'+content.slug} className="cover-link">	</Link>
					</div>
			
		}
}

export default CaseStudyPreview;