import React from 'react'


export default function TitleShortCodes(props){





    let title = props.title 
    if(title){
        title  =  title.split('[notransfrom]').join('<span class="no-upper-case">')
        title  =  title.split('[/notransfrom]').join('</span>')
    }

    return <span dangerouslySetInnerHTML={{__html:title}} />

}