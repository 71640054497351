import React from "react";

import { connect } from "react-redux";
import PageWrapper from "./../PageWrapper";
import MetaTags from "../../metatags/MetaTags";
function JobsPage(props) {
  let content = {};

  if (props.pagesData) {
    let currentPath = props.location.pathname.replace(/\/$/, "");
    content = props.pagesData.filter((page, i) => {
      return "/" + page.slug === currentPath;
    })[0];
  }

  return (
    <PageWrapper>
      <MetaTags {...content} {...props} />
      <div className="jobs-page margin-top-page">
        <div className="content-row">
          <div className="col-2-offset col-8">
            <h2
              className="grey-line"
              dangerouslySetInnerHTML={{ __html: content.title.rendered }}
            />
            <div
              className="large-text text-area page-intro-copy"
              dangerouslySetInnerHTML={{ __html: content.acf.intro_copy }}
            ></div>
          </div>
        </div>

        <div className="content-row">
          <div className="col-3-offset col-6">
            {content.acf.jobs && content.acf.jobs.length > 0
              ? content.acf?.jobs?.map((job, i) => {
                  return (
                    <div className="job" key={"j" + i}>
                      <h3
                        className="job-title"
                        dangerouslySetInnerHTML={{ __html: job.title }}
                      />

                      <div
                        className="job-description"
                        dangerouslySetInnerHTML={{ __html: job?.Text }}
                      />
                      {job.apply_link && (
                        <div className="apply-link">
                          <a href={job.apply_link.url}>
                            {job.apply_link.title}
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  const { pagesData, optionsData } = state;
  return {
    pagesData,
    optionsData,
  };
}

export default connect(mapStateToProps)(JobsPage);
