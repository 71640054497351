import React from "react";

import { connect } from "react-redux";
import CaseStudyPreview from "./CaseStudyPreview";
import BackgroundVideo from "./../../video/BackgroundVideo";
import PageWrapper from "./../PageWrapper";
import VideoPlayer from "../../video/VideoPlayer";
import VimeoVideoPlayer from "../../video/VimeoVideoPlayer";
import MetaTags from "../../metatags/MetaTags";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.findContent(),
    };

    this.findContent = this.findContent.bind(this);

    this.pickVideo = this.pickVideo.bind(this);
  }

  findContent() {
    let path = this.props.location.pathname;

    if (path === "/") {
      path = "/home-page";
    }
    let content = this.props.pagesData.filter((page, i) => {
      //console.log(page.slug,'/'+page.slug , path,'/'+page.slug === path)
      return "/" + page.slug === path;
    })[0];

    return content;
  }

  componentDidMount() {
    this.pickVideo();
  }

  pickVideo() {
    let videoFile = false;
    let videoId = false;

    if (this.state.content.acf.mp4_or_vimeo_video === "vimeo") {
      //console.log('this.state.content',this.state.content)
      videoId = this.state.content.acf.vimeo_url
        ? this.state.content.acf.vimeo_url.split("/").pop()
        : null;

      //console.log('videoId',videoId)

      this.setState({ videoId });
    } else {
      if (this.state.content.acf.feature_video) {
        videoFile = this.state.content.acf.feature_video.url;

        this.setState({ videoFile });
      }
    }
  }

  render() {
    let content = this.state.content; //this.findContent();

    let backgroundStyle = {};

    if (content.acf.background) {
      backgroundStyle.backgroundImage = `url('${content.acf.background.url}')`;
    }

    return (
      <PageWrapper>
        <MetaTags {...content} {...this.props} />

        <div className="home-page">
          <React.Fragment>
            <div className="hero">
              <div className="background" style={backgroundStyle}>
                {this.state.content.acf.mp4_or_vimeo_video === "vimeo" ? (
                  this.state.videoId ? (
                    <VimeoVideoPlayer
                      playInBackground
                      autoPlay
                      muted
                      loop
                      video={this.state.videoId}
                    />
                  ) : null
                ) : this.state.videoFile ? (
                  <VideoPlayer
                    playInBackground
                    autoPlay
                    muted
                    loop
                    video={this.state.videoFile}
                  />
                ) : null}
              </div>

              {content.acf.hero_text ? (
                <div className="hero-text">
                  <div>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: content.acf.hero_text,
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {content?.acf?.home_page_intro_text && (
              <div className="home-page-intro">
                <div className="content-row">
                  <div className="col-2-offset col-8">
                    <div
                      className="large-text text-area page-intro-copy page-intro-copy--short"
                      dangerouslySetInnerHTML={{
                        __html: content?.acf?.home_page_intro_text,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="case-study-previews">
              {this.props.caseStudiesData
                ? this.props.caseStudiesData.map((item, i) => {
                    return <CaseStudyPreview content={item} key={"cs" + i} />;
                  })
                : "no data"}
            </div>
          </React.Fragment>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { pagesData, caseStudiesData, optionsData } = state;

  return {
    pagesData,
    caseStudiesData,
    optionsData,
  };
};

export default connect(mapStateToProps)(Home);
