import React from 'react'
import InnerHTML from 'dangerously-set-html-content'


class TrackingScripts extends React.Component{


    constructor(props){
        super(props);

        this.state = {

            renderScript:false,
            scriptTimeout: null
        }


        this.triggerScriptRewrite = this.triggerScriptRewrite.bind(this)

    }


    componentDidMount(){

        if(this.props.scripts){
            this.triggerScriptRewrite()
        }

    }
    /*
    shouldComponentUpdate(){
        return false;
    }*/

    componentDidUpdate(oldProps,oldState){

        
        if(oldProps?.location?.pathname!==this.props.location.pathname){
            this.triggerScriptRewrite()
        }
    }

    triggerScriptRewrite(){
       

        clearTimeout(this.state.scriptTimeout)
        let scriptTimeout = setTimeout(()=>{
                this.setState({renderScript:true})
        },10)


        this.setState({renderScript:false,scriptTimeout})
    }


    render(){


        if(this.state.renderScript){
            return <div id="trackingScripts">
                        <InnerHTML html={this.props.scripts} />
                    </div>
        }else{
            return <div className="no-tracking" />
        }
    }
    
}

export default TrackingScripts