import './styles/index.scss'

import React from 'react';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import './modules/data/DataLoader'

import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";

//import Grider from './modules/grider/Grider'

import DataLoader from './modules/data/DataLoader'

import Home from './modules/pages/home/Home'

import CaseStudy from './modules/pages/casestudies/CaseStudy'

import ServicesPage from './modules/pages/services/ServicesPage'

import PeoplePage from './modules/pages/people/PeoplePage'

import Error404 from './modules/pages/Error404'

import Header from './modules/header/Header'

import JobsPage from './modules/pages/jobs/JobsPage'

import {connect} from 'react-redux'

//import Footer from './modules/footer/Footer'

import  { detect } from 'detect-browser';
import ContactPage from './modules/pages/contact/ContactPage';
import TrackingScripts from './modules/trackingscripts/TrackingScripts';

const browser = detect();

  //let location = useLocation();

class App extends React.Component{

  constructor(props){

    super(props)


    this.state = {
      disableHeaderScrollLisnter:false
    }

    this.pageTransitionEnter = this.pageTransitionEnter.bind(this);
    this.pageTransitionExited = this.pageTransitionExited.bind(this);
    this.pageTransitionOnExit =  this.pageTransitionOnExit.bind(this);


    // add a class to the body with the browser name

    document.querySelector('body').classList.add('browser-'+browser.name);

  }

  componentWillMount(){
      window.addEventListener('scroll',()=>{
        //console.log('scrollY',window.scrollY)
      })
  }

  pageTransitionEnter(){


        // add class when the screen is blank
        setTimeout(()=>{
          //document.querySelector('body').classList.add('no-scroll');
          window.scrollTo(0, 0);
        },500);
  }


  pageTransitionExited(){
         document.querySelector('body').classList.remove('no-scroll');

         this.setState({disableHeaderScrollLisnter:false});
  }

  pageTransitionOnExit(node){
      //node.style.position = "fixed";
       //node.style.top = -1 * window.scrollY + "px";

     // console.log('window.scrollY',window.scrollY )

     this.setState({disableHeaderScrollLisnter:true});
  }

 


 shouldComponentUpdate(){
    return true;
 }



render(){

  const {caseStudiesData,pagesData,location,optionsData} = this.props





    return (
      <DataLoader>
        <div className="kastner-london" >
           
        {caseStudiesData && pagesData && optionsData ?
          <div className="page-wrapper">
          {
           <Header showHeader={true} disableScrollListener={this.state.disableHeaderScrollLisnter} />
         }
            <TransitionGroup>
              {/*
                This is no different than other usage of
                <CSSTransition>, just make sure to pass
                `location` to `Switch` so it can match
                the old location as it animates out.
              */}
              <CSSTransition
                key={location.key}
                classNames="page-fade"
                timeout={500}
                onEnter={this.pageTransitionEnter}

               

                onExited={this.pageTransitionExited}

                onExit={this.pageTransitionOnExit}
              >
                <Switch location={location}>
                


                  {
                    (pagesData?

                        pagesData.map((page,i)=>{



                        if(page.template==="template-home.php"){


                     
                          return <Route key={"p"+i} exact path="/">
                                  <Home location={location} />
                                </Route>

                        
                         }else if(page.template==="template-services.php"){
                            return ( <Route key={"p"+i} exact path={'/'+page.slug}>
                                        <ServicesPage location={location} />
                                      </Route> )
                          }else if(page.template==="template-contact.php"){
                            return ( <Route key={"p"+i} exact path={'/'+page.slug}>
                                        <ContactPage location={location} />
                                      </Route> )
                          
                          }else if(page.template==="template-people.php"){


                            return ( <Route key={"p"+i} exact path={'/'+page.slug}>
                                        <PeoplePage location={location} />
                                      </Route> )
                          }else if(page.template==="template-jobs.php"){


                            return ( <Route key={"p"+i} exact path={'/'+page.slug}>
                                        <JobsPage location={location} />
                                      </Route> )
                          }
                          return null;
                        })

                       

                    :null)
                  }


               

                  {
                       // Projects

                      (caseStudiesData?
                        caseStudiesData.map((c,i)=>{
                            return (<Route key={'cs'+i} location={location} exact path={"/casestudies/"+c.slug}>
                                        <CaseStudy {...c} location={location} caseStudiesData={caseStudiesData} optionsData={optionsData} />
                                    </Route>)

                        })

                        : null )
                      }
                  

                  {
                  <Route>
                    <Error404 />
                  </Route>}
                </Switch>

              </CSSTransition>
          </TransitionGroup>
         
          </div>
           : null}
            {
          }
          {
           //<Grider cols={12}/>
         }
      </div>

         {optionsData?
          <TrackingScripts scripts={this?.props?.optionsData?.tracking_scripts} location={location} />
         : null }

    </DataLoader>

  );

  }
}

const mapStateToProps = (state)=>{

      const {caseStudiesData,pagesData,optionsData} = state


        return{
            caseStudiesData,
            pagesData,
            optionsData
        }
}

export default withRouter(connect(mapStateToProps)(App));
