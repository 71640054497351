import React from 'react'

import {connect} from 'react-redux'
import MetaTags from '../../metatags/MetaTags';
import PageWrapper from './../PageWrapper';

function ContactPage(props){


	console.log(props)

	let content = {}

		if(props.pagesData){

			let currentPath = props.location.pathname.replace(/\/$/, "");
			content = props.pagesData.filter((page,i)=>{
				return ('/'+page.slug===currentPath)
			})[0]

		
		}

	


	return <PageWrapper>
			<MetaTags {...content} {...props} />
			
			<div className="contact-page margin-top-page">

					<div className="content-row">
							<div className="col-2-offset col-8">

									<h2 className="grey-line" dangerouslySetInnerHTML={{__html:content.title.rendered}} />

									{content.acf.intro_copy &&
										<div className="large-text text-area page-intro-copy" dangerouslySetInnerHTML={{__html:content.acf.intro_copy}} />


									}
							</div>	
					</div>

					<div className="content-row">
							<div className="col-2-offset col-8">
								<div className="contacts">
									{content.acf?.contacts?.map((contact,i)=>{
											return <div key={"contact"+i} className="contact">
														<div className="inner">
															<a href={"mailto:"+contact.email}>
															<h3 dangerouslySetInnerHTML={{__html:contact.label}} />
															<div className="email">{contact.email}</div></a>
														</div>
													</div>
									})}
								</div>
							</div>
					</div>



			</div>
			</PageWrapper>
}

function mapStateToProps(state){
	const {pagesData,optionsData} = state
	return {
		pagesData,
		optionsData
	}
}

export default connect(mapStateToProps)(ContactPage)