import React from 'react'
import VideoPlayer from '../../video/VideoPlayer'
import VimeoVideoPlayer from '../../video/VimeoVideoPlayer'
import Player from '@vimeo/player';



class VideoPlayerRow extends React.Component{



 	constructor(props){

 		super(props)


 		let videoFile = false
 		let videoId = false

 		if(this.props.mp4_or_vimeo_video==='vimeo'){

 			//console.log('Video',this.props)
 			videoId = (this.props.vimeo_url)? this.props.vimeo_url.split('/').pop() : null

 		}else{
 			if(this.props.video){
 				videoFile=this.props.video.url

 			}
 		}

 		this.state={
 				videoId,
 				videoFile
 		}

 	}





  

  componentDidMount(){

  	if(this.props.mp4_or_vimeo_video==='vimeo' && this.state.videoId){

  	}
  }




	render(){


      const classes = (this.props.options.width==='12/12'? "col-12 col-0-offset" : "col-10 col-1-offset" )
      
	  const startVideoMuted = this.props.options?.autoplay;

	  //console.log('startVideoMuted',startVideoMuted)

		return <div className={"content-row video-row use-margin"} ref={this.container}>
					<div className={classes}>

            {this.props.mp4_or_vimeo_video==='vimeo' ?

              <VimeoVideoPlayer ref={this.video} {...this.props} video={this.state.videoId} startVideoMuted={startVideoMuted} />

            :
				this.state.videoFile?
								<VideoPlayer ref={this.video} {...this.props} playInBackground={startVideoMuted} startVideoMuted={startVideoMuted}  video={this.state.videoFile}/>
              : null 
            }

					</div>
				</div>
		}
}

export default VideoPlayerRow;