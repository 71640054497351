import React from 'react'
import { gsap } from "gsap";

import {TimelineMax,Power3,Power2} from 'gsap'
//import DrawSVG from "gsap/DrawSVGPlugin";
//import ScrollToPlugin from "gsap/ScrollToPlugin";
//import SVG from 'react-inlinesvg';
import { CustomEase } from "gsap/CustomEase";

import { CustomBounce } from "gsap/CustomBounce";

gsap.registerPlugin(CustomEase, CustomBounce);


class ScrollArrow extends React.Component {


  constructor(props){
    super();

    this.state={
        arrow_tl:null,

    }

    this.setupAnimation = this.setupAnimation.bind(this)
    this.scrollDown = this.scrollDown.bind(this)

    this.findNextScrollAnchor = this.findNextScrollAnchor.bind(this)

    this.arrowContainer = React.createRef();
  }

  scrollDown(){
      let newY = this.findNextScrollAnchor()

      let time = this.props.scrollDownAnimationTime | 1.2

      //console.log('newY',newY)


      //TweenMax.to(window,time,{scrollTo:{y:newY,autoKill:false},ease:Circ.easeOut})
      //window.scrollTo(0, newY);
      window.scroll({
            top: newY,
            behavior: 'smooth' 
          });

  }

  findNextScrollAnchor(){

      let anchors = document.querySelectorAll('.scroll-anchor')

      let bodyRect = document.body.getBoundingClientRect()

      let thisYpos = this.arrowContainer.current.getBoundingClientRect().top - bodyRect.top

      let scrollTargetY = null

      //console.log("Arrow pos", thisYpos);
      for(let i=0;i < anchors.length;i++){
        let anchor = anchors[i]
        let elemRect = anchor.getBoundingClientRect()
        let anchorPosition  = elemRect.top - bodyRect.top;

        //console.log("anchor pos",anchorPosition);


        if(anchorPosition > thisYpos && !scrollTargetY){
            scrollTargetY = anchorPosition;
        }

      }




      return scrollTargetY;

  }


  setupAnimation(){

    let arrow_tl = new TimelineMax({repeat:0});

    let arrow = this.arrowContainer.current.querySelector('.inner-arrow');
    //let polyline = arrow.querySelector('polyline')



      CustomBounce.create("myBounce", {strength:0.6, squash:0, squashID:"myBounce-squash"});


        //arrow_tl.fromTo(polyline, 1, {drawSVG:'0%'},{drawSVG:'100%', ease:Power2.easeInOut})
        arrow_tl.fromTo(this.arrowContainer.current,1,{width:0,ease:Power2.easeInOut},{width:38})
        arrow_tl.to(arrow, .3, {y:-15,ease:Power3.easeOut})
        arrow_tl.to(arrow, .5, {y:0,ease:'myBounce'})
        arrow_tl.add(()=>{
          arrow.setAttribute('style','')
        })


        //arrow_tl.to(arrow, .2, {y:0,ease:Back.easeOut})
        //arrow_tl.to(arrow, .5, {y:20,ease:Back.easeIn})
        //arrow_tl.to(arrow, .2, {y:0,ease:Back.easeOut})


        //arrow_tl.to(arrow, .2, {y:-20,ease:Back.easeInOut})
        //arrow_tl.to(arrow, .2, {y:0,ease:Back.easeInOut})


  }

  componentDidMount(){
    this.setupAnimation();
    this.findNextScrollAnchor();
    window.addEventListener('resize',this.findNextScrollAnchor)
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.findNextScrollAnchor)
  }


  render () {


    let click = this.scrollDown
    let extraClasses = this.props.className || " "

    return(
          <div className={"scroll-arrow "+extraClasses} ref={this.arrowContainer} style={this.props.style} onClick={click}>
            <div className="inner-arrow">

            </div>
          </div>
    )

  }
}

export default ScrollArrow;
