import React, { useState } from 'react';




class Person extends React.Component{

	constructor(props){
		super(props)

		this.state = {
			width:'auto',
			open:false,
			overFlowTimeout:null,
			hideOverFlow:true
		}

		this.toggleOpen = this.toggleOpen.bind(this);

		this.closePerson = this.closePerson.bind(this);
		this.openPerson = this.openPerson.bind(this);

		this.onResize = this.onResize.bind(this);

		this.externalOpen  = this.externalOpen.bind(this)

		this.getTextMinHight = this.getTextMinHight.bind(this)


		this.container = React.createRef();
	}

	toggleOpen(){
				let textMinHeight = this.container.current.querySelector(".text-container").offsetHeight;

		this.setState({open:!this.state.open,textMinHeight})
	}

	openPerson(){
		let textMinHeight = this.container.current.querySelector(".text-container").offsetHeight;

		this.setState({open:true,textMinHeight})

	}

	closePerson(){
		this.setState({open:false})
	}

	externalOpen(){
		if(this.props.openCB){
			this.props.openCB(this.props.name)
		}
	}


	

	componentDidMount(){
		//this.checkWidth();
		//window.addEventListener('resize',this.checkWidth)
	}


	getTextMinHight(){

		let height = 0;

		if(this.container.current){
			height = this.container.current.querySelector(".text-container").offsetHeight
		}

		return height
	}



	onResize(){
		if(this.state.open){
			//this.openService();
		}
	}

	componentDidMount(){
		window.addEventListener('resize',this.onResize)
	}

	componentWillUnmount(){
		window.removeEventListener('resize',this.onResize)
	}

	componentDidUpdate(oldProps,newState){

		if(oldProps.open && !this.props.open){


			let overFlowTimeout = setTimeout(()=>{
					this.setState({hideOverFlow:true})
				},500);

			this.setState({overFlowTimeout});
		}

		if(!oldProps.open && this.props.open){
			clearTimeout(this.state.overFlowTimeout)
			this.setState({hideOverFlow:false})
		}
	}

	render(){



	let textStyle = {maxHeight:'0px'}
	let textMinHeight = 0

		if(this.props.open){

			 textMinHeight = this.getTextMinHight();
		

			textStyle.maxHeight = textMinHeight+(150+48)+'px';

		}

			textStyle.minHeight = textMinHeight +'px';


	// make 2 lines 

	let  name = this.props.name

	if(this.props.splitName){
		 name = this.props.name.split(' ').join('<br />')
	}


	// hide overflow 

	let hideOverFlow = this.state.hideOverFlow;


	if(this.props.open){

	}

	return <div ref={this.container} 
				style={{flexBasis:this.props.width}} 
				//className={"person "+( this.state.open? 'open' : 'closed')} 
				className={"person "+( this.props.open? 'open' : 'closed')+(hideOverFlow? ' hide-overflow': '')} 

				//onMouseOver={this.openPerson} 
				//onMouseOut={this.closePerson} 
				onClick={this.externalOpen} >
				<div className="photo" style={{backgroundImage:`url('${this.props.photo? this.props.photo.url : ''}')`}} />
				<div className="info" style={textStyle}>
					<div className="text-container">
					<div className="title red-text"><strong dangerouslySetInnerHTML={{__html:this.props.title}} /></div>
					<div className="name-wrapper">
						<h3 className="red-line" dangerouslySetInnerHTML={{__html:name}} />
					</div>
					{this.props.bio? 
						<div className="bio"  dangerouslySetInnerHTML={{__html:this.props.bio}}  />
					: null }
					</div>
				</div>
			</div>
		}

	}


export default Person