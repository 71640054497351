import React from 'react'

class Service extends React.Component{


	constructor(props){
		super(props)

		this.state = {
			open:false,
			textMinHeight:0
		}

		this.openService = this.openService.bind(this)
		this.closeService = this.closeService.bind(this)
		this.toggleService = this.toggleService.bind(this)
		this.onResize = this.onResize.bind(this)

		this.container = React.createRef();
	}


	openService(){
				let textMinHeight = this.container.current.querySelector(".inner-text").offsetHeight;

		this.setState({open:true,textMinHeight})
	}

	closeService(){
		this.setState({open:false});
	}

	toggleService(){
		if(this.state.open===false){
			this.openService();
		}else{
			this.closeService();
		}
	}

	onResize(){
		if(this.state.open){
			this.openService();
		}
	}

	componentDidMount(){
		window.addEventListener('resize',this.onResize)
	}

	componentWillUnmount(){
		window.removeEventListener('resize',this.onResize)
	}



	render(){


		const {image,name,text,cta_label,cta_link} = this.props;
		const {open} = this.state;

		let textStyle = {maxHeight:'0px'}

		if(open){
			textStyle.maxHeight = this.state.textMinHeight+(150+48)+'px'
		}

		let ctaUrl;

		if(cta_link){
			ctaUrl = cta_link.url;
			
			if(ctaUrl.includes('mailto')){
				ctaUrl = ctaUrl +'?subject='+name
			}
		}


		return <div ref={this.container} className={"service "+ (open? 'open' : 'closed')+(text? ' ':' no-text')} onClick={this.toggleService}>
																	<div className="service-image" style={{backgroundImage:`url('${image? image.url :''}')`}} />
																	<h3 className={(open?  'red-line' : ' ')} dangerouslySetInnerHTML={{__html:name}} />
																	<div className="text text-area" style={textStyle} >
																		<div className="inner-text" dangerouslySetInnerHTML={{__html:text}} />
																		{cta_label?	


																			

																				<a href={ctaUrl} dangerouslySetInnerHTML={{__html:cta_label}} className="cta-link" />
																			: null
																		}
																	</div>


															   </div>

	}
}

export default Service;