import React from 'react'
import {Helmet} from 'react-helmet'

import {decode} from 'html-entities';


export default function MetaTags(props){


   

    let title = props?.title?.rendered? `Kastner - ${props?.title?.rendered}` : "Kastner"; 

    let pageLink = `${window?.origin}/${props?.slug}`

    
    // Remove page title if homepage

    if(window?.location?.pathname === '/'){
        title ="Kastner"
        pageLink = `${window?.origin}`
    }

 

    

    

        

    return <>{props?.optionsData?.seo_enabled?
            <Helmet>
                <meta charSet="utf-8" />
                <title>{decode(title)}</title>
                <link rel="canonical" href={pageLink} />
                <meta name="keywords" content={decode(props?.acf?.key_words)} />
                <meta name="description" content={decode(props?.acf?.description)} />
            </Helmet>
            : <Helmet>
                <meta charSet="utf-8" />
                <title>{decode(title)}</title>
                <meta name="googlebot" content="noindex, nofollow" />
            </Helmet>

             }
            </>


}