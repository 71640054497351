import React from "react";
import { connect } from "react-redux";

class DataLoader extends React.Component {
  constructor(props) {
    super(props);

    let server = "http://kastnerpromo.local/en/";


    let isLocalHost = window.location.hostname === "localhost";

    if (process.env.NODE_ENV !== "development") {
      server = "/wp/";
    }

    // Sites

    // console.log('location',window.location)

    /*
     Budapest
      Frankfurt
      Los Angeles
      Madrid
      Milan */

    const availableSites = [
      {
        siteHost: "kastnerla.stageserver.co.uk",
        siteHash: "#la",
        dataUrl: "kastnerla.stageserver.co.uk/wp/",
      },

      {
        siteHost: "www.kastner-los-angeles.agency",
        siteHash: "#laLIVE",
        dataUrl: "https://www.kastner-los-angeles.agency/wp/",
      },

      {
        siteHost: "kastnerlondon.stageserver.co.uk",
        siteHash: "#london",
        dataUrl: "http://kastnerlondon.stageserver.co.uk/wp/",
      },

      {
        siteHost: "www.kastner-london.agency",
        siteHash: "#londonLive",
        dataUrl: "https://www.kastner-london.agency/wp/",
      },

      {
        siteHost: "kastnerbudapest.stageserver.co.uk",
        siteHash: "#budapest",
        dataUrl: "http://kastnerbudapest.stageserver.co.uk/wp/",
      },

      {
        siteHost: "kastnerfrankfurt.stageserver.co.uk",
        siteHash: "#frankfurt",
        dataUrl: "http://kastnerfrankfurt.stageserver.co.uk/wp/",
      },

      {
        siteHost: "www.kastner-frankfurt.agency",
        siteHash: "#frankfurtLIVE",
        dataUrl: "https://www.kastner-frankfurt.agency/wp/",
      },

      {
        siteHost: "kastnermadrid.stageserver.co.uk",
        siteHash: "#madrid",
        dataUrl: "http://kastnermadrid.stageserver.co.uk/wp/",
      },

      {
        siteHost: "www.kastner-madrid.agency",
        siteHash: "#madridLIVE",
        dataUrl: "https://www.kastner-madrid.agency/wp/",
      },

      {
        siteHost: "kastnermilan.stageserver.co.uk",
        siteHash: "#milan",
        dataUrl: "//kastnermilan.stageserver.co.uk/wp/",
      },

      {
        siteHost: "www.kastner-budapest.agency",
        siteHash: "#budapestLIVE",
        dataUrl: "https://www.kastner-budapest.agency/wp/",
      },
    ];

    // get the server url

    if (process.env.NODE_ENV === "development") {
      const hash = window.location.hash;

      const currentSite = availableSites.filter((item, i) => {
        return hash === item.siteHash;
      })[0];

      console.log("currentSite dev", currentSite);
    } else {
      const host = window.location.host;

      console.log("host", host);

      const currentSite = availableSites.filter((item, i) => {
        return host === item.siteHost;
      })[0];

      console.log("currentSite", currentSite);

      server = currentSite.dataUrl;
    }

    // use london if localhost
    /*
    if (isLocalHost) {
      //server = 'https://www.kastner-london.agency/wp/'
      server = "https://www.kastner-budapest.agency/wp/";
    }*/

    // check for nounce

    let fetchOptions = {};

    let params = new URL(document.location).searchParams;
    let previewId, previewType;

    if (params) {
      if (params.get("n")) {
        previewId = params.get("id");
        previewType = params.get("type");

        console.log("previewId", previewId);

        let headers;

        if (!isLocalHost) {
          headers = new Headers({
            "Content-Type": "application/json",
            "X-WP-Nonce": params.get("n"),
          });
        }

        fetchOptions = {
          headers: headers,
          redentials: "include",
        };
      }
    }

    this.state = {
      server,
      fetchOptions,
      previewId,
      previewType,
      isLocalHost,
    };

    if (previewId) {
      this.loadPreview();
    }
  }

  loadPreview() {
    //wp/wp-json/wp/v2/posts/180

    console.log("previewId", this.state.previewId);

    let type = this.state.previewType;

    if (type === "teams") {
      type = "team";
    }

    if (type === "page") {
      type = "pages";
    }

    let url =
      this.state.server + "wp-json/wp/v2/" + type + "/" + this.state.previewId;

    // don't forget the s on previewType

    //console.log('location',window.location)

    let options = this.state.fetchOptions;

    fetch(url, options)
      .then((results) => results.json())
      .then((array) => {
        this.previewDataLoaded(array);
      });
  }

  previewDataLoaded(array) {
    console.log("Preview Data:", array);

    let options = this.state.fetchOptions;

    let template = array.template;

    // Check for versions and get the latest
    console.log(
      "array._links['version-history']",
      array._links["version-history"]
    );

    if (array._links["version-history"] && !this.state.isLocalHost) {
      fetch(array._links["version-history"][0].href, options)
        .then((results) => results.json())
        .then((array) => {
          console.log("version-history", array);
          //this.previewDataLoaded(array);

          array[0].template = template;
          this.props.setPreviewData(array[0]);
          this.loadCount();
        });
    } else {
      this.props.setPreviewData(array);
      this.loadCount();
    }
  }

  loadPages() {
    let url = this.state.server + "wp-json/wp/v2/pages/?per_page=100";

    fetch(url)
      .then((results) => results.json())
      .then((array) => {
        this.props.setPagesData(array);
        this.loadCount();
      });
  }

  loadCaseStudies() {
    let url =
      this.state.server + "wp-json/wp/v2/casestudies/?_embed=1&per_page=100";

    fetch(url)
      .then((results) => results.json())
      .then((array) => {
        this.props.setCaseStudies(array);
        this.loadCount();
      });
  }

  loadOptions() {
    let url = this.state.server + "wp-json/acf/v3/options/site-options/";

    fetch(url)
      .then((results) => results.json())
      .then((array) => {
        this.props.setOptionsData(array);
        this.loadCount();
      });
  }

  componentDidMount() {
    this.loadPages();
    this.loadCaseStudies();
    this.loadOptions();

    //this.loadBlog();
    //this.loadUsers();
  }

  loadCount() {
    if (
      this.props.pagesData &&
      this.props.caseStudiesData &&
      this.props.optionsData
    ) {
      console.log("Pages Loaded");

      if (this.state.previewId) {
        if (this.props.previewData) {
          this.setState({ loaded: true });
        }
      } else {
        this.setState({ loaded: true });
      }
    }
  }

  render() {
    return (
      <div id="dataLoader">
        {this.state.loaded ? (
          this.props.children
        ) : (
          <div className="loading">
            <img src="/assets/global/logo.svg" alt="Kastner" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const optionsData = state.optionsData;
  const pagesData = state.pagesData;
  const caseStudiesData = state.caseStudiesData;
  const previewData = state.previewData;

  return {
    caseStudiesData,
    optionsData,
    pagesData,
    previewData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPagesData: (data) => {
      //console.log('ScrollIndex',index,max,dir)
      dispatch({ type: "ADD_PAGES_DATA", payload: data });
    },
    setOptionsData: (data) => {
      //console.log('ScrollIndex',index,max,dir)
      dispatch({ type: "ADD_OPTIONS_DATA", payload: data });
    },

    setCaseStudies: (data) => {
      dispatch({ type: "ADD_CASESTUDIES_DATA", payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);
