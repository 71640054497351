import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import TitleShortCodes from '../../misc/TitleShortCodes';

class RelatedCaseStudy extends React.Component{



	getContent(){

		if(this.props.caseStudiesData){


				const cs = this.props.caseStudiesData.filter((item,i)=>{


					return (this.props.id === item.id)
				})


				return cs[0]

		}

	}


	render(){


		const content = this.getContent();

		if(content){



			return (<div className="related-case-study">
						<Link to={"/casestudies/"+content.slug}>
							<div className="category" >
								{content.acf.project_type?
									<strong dangerouslySetInnerHTML={{__html:content.acf.project_type }}  />
								: <strong>&nbsp;</strong>}
							</div>
							<h4><TitleShortCodes title={content.title.rendered} /></h4>
						</Link>
					</div>
			)
		}else {
			return null
		}

	}

}

const mapStateToProps = (state)=>{

	return {
		...state
	}
}
export default connect(mapStateToProps)(RelatedCaseStudy)