import React from 'react'
import {NavLink} from 'react-router-dom'
import Menu from '../menu/Menu'
import MenuBurger from '../menu/MenuBurger'
import {TimelineMax} from 'gsap'


class Header extends React.Component {

  constructor(){
    super();
    this.state = {
      open:false,
      dir:'up',
      lastYpos:0,
      startUpScrollYPos: 0,
      scollDistance: 0,
      startScrollPosition: 0,
      currentScrollDistance: 0,
      topPosition:0,
      hideTimeout:null,
      menuOpen:false,
      introAnimationIsRunning: false,
      positionClass:'fixed',
      loadAnimation_tl: null,
      logoIsLoaded:false,
      mouseOverHeader:false,
    }

    this.onScroll = this.onScroll.bind(this);
    this.autoHide = this.autoHide.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    //this.triggerAnimateIn = this.triggerAnimateIn.bind(this)

    this.logoOnload = this.logoOnload.bind(this)
    this.setUpLoadAnimation = this.setUpLoadAnimation.bind(this)

    this.logoClick = this.logoClick.bind(this)

    this.closeMenu = this.closeMenu.bind(this)

    this.logo = React.createRef();
    this.divider = React.createRef();

    this.MenuBurger = React.createRef();

  }

  componentDidMount(){
  //  console.log("Home Page",window.location.pathname,(window.location.pathname ==='/'));

   
      window.addEventListener('scroll',this.onScroll)
    

      if(!this.isMobile()){
        window.addEventListener('mousemove',this.mouseMove)
      }

      this.setUpLoadAnimation();


     window.addEventListener('scroll',this.bodyScroll);
      //console.log('document.querySelector(body)',document.querySelector('body'));


      let hideTimeout = setTimeout(this.autoHide,3000);
      this.setState({dir:'up',hideTimeout})
  }





  onScroll(e){

      //console.log('scroll',this.props.disableScrollListener)

      if(!this.props.disableScrollListener){

        let scrollContainer = window //document.querySelector('.page-wrapper')

        let newState = {}
        //let windowScrollY = scrollContainer.scrollY || scrollContainer.scrollTop || 0;
        let windowScrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // disable if the intro animation is running






            if(this.state.lastYpos > windowScrollY){
              newState.dir = 'up'
              newState.lastYpos = windowScrollY;
              newState.topPosition = (this.state.startUpScrollYPos-windowScrollY)-92;

              if(newState.topPosition>0){
                  newState.topPosition=0;
              }

              clearTimeout(this.state.hideTimeout)
              newState.hideTimeout = setTimeout(this.autoHide,2000);
            }

            if(this.state.lastYpos < windowScrollY){
                newState.dir="down"
                newState.lastYpos = windowScrollY;
            }

            //console.log(this.state.lastYpos , windowScrollY ,  newState.dir);

            if(this.state.dir==='down' & newState.dir ==='up'){
              //console.log("Start Up Scroll");
              newState.startScrollPosition = windowScrollY;
            }

            if(this.state.dir==='up' & newState.dir ==='down'){
              //console.log("Start Down Scroll");
              newState.startScrollPosition = windowScrollY;
            }


            newState.currentScrollDistance = this.state.startScrollPosition - windowScrollY


            this.setState({...newState})
        }








  }

  mouseMove(e){



    if(e.clientY < 100){
      clearTimeout(this.state.hideTimeout)
      let hideTimeout = setTimeout(this.autoHide,2000);
      this.setState({dir:'up',hideTimeout})
    }


    if(e.clientY < 100 && !this.state.mouseOverHeader){
        this.setState({mouseOverHeader:true})
        console.log('Show Header');
    }else if(e.clientY > 100 && this.state.mouseOverHeader){
      this.setState({mouseOverHeader:false})
        console.log('Hide Header');
    }

  }

  isMobile() {
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;

         // Windows Phone must come first because its UA also contains "Android"
       if (/windows phone/i.test(userAgent)) {
           return true
       }

       if (/android/i.test(userAgent)) {
             return true
       }

       // iOS detection from: http://stackoverflow.com/a/9039885/177710
       if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
           return true
       }

       return false
   }


  componentDidUpdate(oldProps,oldState){
    //  console.log("Header Will Update",newProps,newState);

      if(oldProps.showHeader===false && this.props.showHeader===true){
        this.state.loadAnimation_tl.play()
      }

  }




  autoHide(){
    let windowScrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('autohide')

    if(windowScrollY > 90 || !this.state.mouseOverHeader){
      this.setState({dir:''})
    }else{
      //console.log("don't autohide menu");
    }
  }


  toggleMenu(){






    // the value is reversed because the state isn't updated.
    if(!this.state.menuOpen){
      document.querySelector('body').classList.add('no-scroll')
    }else{
      document.querySelector('body').classList.remove('no-scroll')
    }
    //document.querySelector('body').classList.add('no-scroll')

    this.setState({menuOpen:!this.state.menuOpen})

    if(this.state.menuOpen){
              setTimeout(()=>{
                this.MenuBurger.current.reset();
              },500)
    }

  }

  closeMenu(){
        this.setState({menuOpen:false})
         document.querySelector('body').classList.remove('no-scroll')

  }

  logoClick(){
    if(this.state.menuOpen){
        this.toggleMenu()
    }
  }


  logoOnload(){
    if(this.props.showHeader){
      this.state.loadAnimation_tl.play()
    }
    this.setState({logoIsLoaded:true})
  }

  setUpLoadAnimation(){

    const loadAnimation_tl = new TimelineMax({repeat:0})

    loadAnimation_tl.from(this.divider.current,.5,{height:0})
    loadAnimation_tl.from(this.logo.current,.5,{alpha:0},'-=0')
    loadAnimation_tl.from(document.querySelector('.menu-burger'),.5,{alpha:0},'-=.5')

    loadAnimation_tl.pause()
    this.setState({loadAnimation_tl})

  }




  render () {



    let scrollDistance = this.state.currentScrollDistance
   let windowScrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // if the window is right up at the top set 110 to trigger header
    if(windowScrollY < 86){
      scrollDistance = 110;
    }

    // console.log('scrollDistance',scrollDistance,this.state.dir);

    let showClass = (this.state.dir==='up' && scrollDistance > 100)? 'show' : 'hide' ;

    //console.log('showClass',showClass,this.state.mouseOverHeader);

    //console.log('scrollDistance',scrollDistance)

    //console.log('showClass 1',showClass)
    /*
    // Always show if scroll is lower than 90 
    if(windowScrollY < 86 || this.state.mouseOverHeader, windowScrollY < 86){
      showClass = 'show'
    }*/

     // Always show if scroll is lower than 90 and mobile
    if(windowScrollY < 86 && window.innerWidth < 769){
      showClass = 'show'
    }


    if( this.state.mouseOverHeader){
         showClass = 'show'
    }


    /// always ashow if the menu is open
      if(this.state.menuOpen){
        showClass="show"
      }




      // Intro animation is running
      if(this.state.introAnimationIsRunning){
        showClass="show intro-animation"
      }


      let positionClass = this.state.positionClass
      let hideWrapperStyle = {}//(!this.props.showHeader)? {display:'none'} : {} ;



    return(<div style={hideWrapperStyle}>

        <header className={showClass + " "+positionClass} >


            <Menu open={this.state.menuOpen} toggle={this.toggleMenu} />

          <div className="background">
            <NavLink to='/' onClick={this.closeMenu} >
            <div className="logo" id="logo" ref={this.logo}>
                  <img className="k" onLoad={this.logoOnload} src="/assets/logo/K.png" alt="" />
                  <img className="a" src="/assets/logo/A.png" alt="" />
                  <img className="s" src="/assets/logo/S.png" alt=""  />
                  <img className="t" src="/assets/logo/T.png" alt="" />
                  <img className="n" src="/assets/logo/N.png" alt="" />
                  <img className="e" src="/assets/logo/E.png" alt="" />
                  <img className="r" src="/assets/logo/R.png" alt="" />
                </div>
              </NavLink>
            <div className="divider" ref={this.divider} />
            <MenuBurger ref={this.MenuBurger} open={this.state.menuOpen} onClick={this.toggleMenu} showHeader={this.props.showHeader}  />
          </div>


        </header>
      </div>
    )
  }
}

export default Header;
